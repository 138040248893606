<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">{{ title }}</th>
          <th class="text-left">{{ textLeft }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td class="d-flex align-center">{{ item[keyTable] }}</td>
          <td>{{ item.media ? item.media : item.total }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TabelaAtendimentos",
  props: {
    data: {
      type: Array,
    },
    title: { type: String },
    keyTable: { type: String },
    textLeft: { type: String },
  },
};
</script>

<style scoped></style>
