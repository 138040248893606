<template>
  <div>
    <v-row dense class="pb-4 justify-end">
      <v-col cols="3" class="py-0 pb-4">
        <v-menu
          ref="menuDataInicio"
          v-model="menuDataInicio"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.dataInicio"
              placeholder="Data Inicial"
              outlined
              dense
              :clearable="true"
              label="Data Inicial"
              required
              :rules="rules.genericRules"
              v-bind="attrs"
              v-on="on"
              :hide-details="true"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="formData.dataInicio"
            no-title
            scrollable
            locale="pt-br"
            :max="formData.dataTermino"
            color="black"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuDataInicio = false">
              Fechar
            </v-btn>
            <v-btn text color="primary" @click="menuDataInicio = false">
              Selecionar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="py-0 pb-4">
        <v-menu
          ref="menuDataInicio"
          v-model="menuDataTermino"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.dataTermino"
              placeholder="Data Final"
              outlined
              dense
              :clearable="true"
              label="Data Final"
              required
              :rules="rules.genericRules"
              v-bind="attrs"
              v-on="on"
              :hide-details="true"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="formData.dataTermino"
            no-title
            scrollable
            locale="pt-br"
            :max="nowDate"
            :min="formData.dataInicio"
            color="black"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuDataTermino = false">
              Fechar
            </v-btn>
            <v-btn text color="primary" @click="menuDataTermino = false">
              Selecionar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="py-0 pb-4">
        <v-menu
          ref="menuComparacaoDataInicio"
          v-model="menuComparacaoDataInicio"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.comparacaoDataInicio"
              placeholder="Data de Comparação Inicial"
              outlined
              dense
              :clearable="true"
              label="Data de Comparação Inicial"
              required
              v-bind="attrs"
              v-on="on"
              :hide-details="true"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="formData.comparacaoDataInicio"
            no-title
            scrollable
            locale="pt-br"
            color="black"
            :max="nowDate"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menuComparacaoDataInicio = false"
            >
              Fechar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="menuComparacaoDataInicio = false"
            >
              Selecionar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="py-0 pb-4">
        <v-menu
          ref="menuComparacaoDataTermino"
          v-model="menuComparacaoDataTermino"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.comparacaoDataTermino"
              placeholder="Data de Comparação Final"
              outlined
              dense
              :clearable="true"
              label="Data de Comparação Final"
              required
              v-bind="attrs"
              v-on="on"
              :hide-details="true"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="formData.comparacaoDataTermino"
            no-title
            scrollable
            locale="pt-br"
            color="black"
            :min="formData.comparacaoDataInicio"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menuComparacaoDataTermino = false"
            >
              Fechar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="menuComparacaoDataTermino = false"
            >
              Selecionar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="py-0 pb-4" v-if="funcionarioId">
        <v-autocomplete
          v-if="medicoAprovador"
          v-model="formData.funcionarioId"
          :items="listMedicoAprovadores"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          placeholder="Médicos aprovador"
          :hide-details="true"
          :clearable="true"
          outlined
          dense
        ></v-autocomplete>
        <v-autocomplete
          v-else
          v-model="formData.funcionarioId"
          :items="listColaborador"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          placeholder="Colaborador"
          :hide-details="true"
          :clearable="true"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="3" class="py-0 pb-4" v-if="linhaId">
        <v-autocomplete
          v-model="formData.linhaId"
          :items="listLinhas"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          placeholder="Linha"
          :hide-details="true"
          :clearable="true"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="3" class="py-0 pb-4" v-if="produtoId">
        <v-autocomplete
          v-model="formData.produtoId"
          :items="listProdutos"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          placeholder="Produtos"
          :hide-details="true"
          :clearable="true"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="3" class="py-0 pb-4" v-if="especilidadeId">
        <v-autocomplete
          v-model="formData.especilidadeId"
          :items="listEspecialidades"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          placeholder="Especialidade"
          :hide-details="true"
          :clearable="true"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col :cols="!consolidado ? '6' : '3'" class="py-0 pb-4" v-if="assunto">
        <v-autocomplete
          v-model="formData.assunto"
          :items="listAssuntos"
          hide-no-data
          hide-selected
          item-text="name"
          item-value="value"
          placeholder="Motivos"
          :hide-details="true"
          :clearable="true"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col :cols="!funcionarioId ? '6' : '3'" class="py-0 pb-4" v-if="consolidado">
        <v-switch
          class="mt-0 pt-1"
          dense
          v-model="formData.consolidado"
          label="Consolidar todos os dados"
        ></v-switch>
      </v-col>
      <v-col cols="6" class="pb-4">
        <v-row dense>
          <v-col>
            <v-btn
              depressed
              color="green accent-4"
              dark
              :loading="loadingBtn"
              block
              @click="$emit('baixar', formData)"
              >EXPORTAR XLS</v-btn
            ></v-col
          >

          <v-col>
            <v-btn
              depressed
              color="fmq_gray"
              dark
              :disabled="loadingBtn"
              :loading="loading"
              block
              @click="$emit('buscar', formData)"
              >Buscar</v-btn
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import { listColaborador, listarMedicoAprovadores } from "@/services/user";
import { listarLinha } from "@/services/linhas";
import { listarProdutos } from "@/services/produtos";
import { listarEspecialidade } from "@/services/especialidades.js";
export default {
  name: "FilterAtendimentos",
  props: {
    loadingBtn: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    funcionarioId: {
      type: Boolean,
      default: true,
    },
    linhaId: {
      type: Boolean,
      default: true,
    },
    produtoId: {
      type: Boolean,
      default: true,
    },
    especilidadeId: {
      type: Boolean,
      default: true,
    },
    assunto: {
      type: Boolean,
      default: true,
    },
    consolidado: {
      type: Boolean,
      default: true,
    },
    medicoAprovador: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formData: {
      dataInicio: "",
      dataTermino: "",
      comparacaoDataInicio: "",
      comparacaoDataTermino: "",
      funcionarioId: null,
      linhaId: null,
      produtoId: null,
      especilidadeId: null,
      assunto: null,
      consolidado: true,
    },
    nowDate: getNowDate(),
    firstDateOfYear: getFirstDateOfYear(),
    rules: rules,
    listColaborador: [],
    listMedicoAprovadores: [],
    listLinhas: [],
    listProdutos: [],
    listEspecialidades: [],
    menuDataInicio: false,
    menuDataTermino: false,
    menuComparacaoDataInicio: false,
    menuComparacaoDataTermino: false,
    listAssuntos: [
      { name: "Comparativo", value: "Comparativo" },
      { name: "Comprovação de segurança", value: "Comprovação de segurança" },
      {
        name: "Comprovação de eficácia e segurança",
        value: "Comprovação de eficácia e segurança",
      },
      { name: "Mecanismo de ação", value: "Mecanismo de ação" },
      {
        name: "Material de marketing (Separata ou Monografia)",
        value: "Material de marketing (Separata ou Monografia)",
      },
      { name: "Modo de uso/posologia", value: "Modo de uso/posologia" },
      { name: "Composição do produto", value: "Composição do produto" },
      { name: "Referência bibliográfica", value: "Referência bibliográfica" },
      { name: "Padronização", value: "Padronização" },
      { name: "Outros", value: "Outros" },
      { name: "Todos", value: null },
    ],
  }),
  created() {
    this.formData.dataInicio = this.firstDateOfYear;
    this.formData.dataTermino = this.nowDate;
    this.$emit("buscar", this.formData);
    this.getColaborador();
    this.getMedicoAprovadores();
    this.getLinhas();
    this.getProdutos();
    this.listEspecialidade();
  },
  methods: {
    async getColaborador() {
      const resp = await listColaborador({user_sistema: '1'});
      this.listColaborador = resp.data;
      console.log(this.listColaborador)
    },
    async getMedicoAprovadores() {
      const resp = await listarMedicoAprovadores();
      this.listMedicoAprovadores = resp.data;
    },
    async getLinhas() {
      const resp = await listarLinha();
      this.listLinhas = resp.data;
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async listEspecialidade() {
      const resp = await listarEspecialidade();
      this.listEspecialidades = resp.data;
    },
  },
};
function getFirstDateOfYear() {
  const now = new Date();
  const firstDateOfYear = new Date(now.getFullYear(), 0, 1);
  return firstDateOfYear.toISOString().slice(0, 10);
}

function getNowDate() {
  const now = new Date();
  const saoPauloOffset = -3 * 60;
  const localOffset = now.getTimezoneOffset();
  const offsetDifference = saoPauloOffset - localOffset;
  const saoPauloTime = new Date(now.getTime() + offsetDifference * 60 * 1000);
  const year = saoPauloTime.getFullYear();
  const month = String(saoPauloTime.getMonth() + 1).padStart(2, "0");
  const day = String(saoPauloTime.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
</script>

<style></style>
